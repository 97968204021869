import { Link } from "react-router-dom";
import { Logo } from "src/components/Logo";
import { modeOptions } from "src/constants";
import { Footer } from "src/components/Footer";
import { Selector } from "src/components/Selector";

interface ScreenProps {
  children: React.ReactNode | React.ReactNode[];
}
export const Screen: React.FC<ScreenProps> = ({ children }) => (
  <div className="min-h-screen flex flex-col">
    <div className="container max-w-xl mx-auto px-4 lg:px-8 py-4 flex-1 flex flex-col">
      <header className="flex justify-between items-center">
        <div className="flex flex-col justify-center">
          <Link to="/" className="logo text-sm lg:text-base flex gap-2 items-center">
            <Logo className="w-6" />
            <div className="flex flex-col">
              <span className="dark:text-zinc-300">SYNCULATOR</span>
              <span className="text-xs font-thin dark:text-zinc-300">
                by Stevie J Jones
              </span>
            </div>
          </Link>
        </div>
        <Selector options={modeOptions} label="Select Tool" />
      </header>
      {children}
    </div>
    <Footer />
  </div>
);
