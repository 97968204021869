import classNames from "classnames";

interface NumericInputProps {
  value?: string | number;
  setValue: (value: string) => void;
  label: string;
  autoFocus?: boolean;
  placeholder?: string;
}

export const NumericInput: React.FC<NumericInputProps> = ({
  value,
  setValue,
  label,
  autoFocus = false,
  placeholder = "bpm",
}) => {
  const inputClassName = classNames(
    `${label.toLowerCase()}-input`,
    "text-center numeric-input bg-white px-3 py-1 dark:bg-white dark:text-black rounded-md xl:text-xl flex-none max-w-[80px] focus:outline-none focus:ring-4 focus:ring-yellow-400 transition-all duration-200 flex-none",
    {
      "font-mono": !!value,
    }
  );

  return (
    <div className="flex gap-2 items-center justify-start">
      <input
        type="number"
        step="1"
        placeholder={placeholder}
        className={inputClassName}
        autoFocus={autoFocus}
        value={value || ""}
        onChange={(event) => setValue(event.target.value)}
      />
      <span className="flex-1 text-sm xl:text-base dark:text-zinc-100">{label}</span>
    </div>
  );
};
