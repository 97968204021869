import { Selector } from "./Selector";
import { metronomeSounds } from "src/constants";
import { useCallback, useContext, useMemo } from "react";
import { MetronomeContext } from "src/context/MetronomeContext";

export const SoundSelector: React.FC = () => {
  const { sound, setSound } = useContext(MetronomeContext);

  const options = useMemo(
    () =>
      [...metronomeSounds].map((sound) => ({
        label: sound.name,
      })),
    []
  );

  const _onSelect = useCallback(
    (value: string) => {
      const selectedSound = metronomeSounds.find((sound) => sound.name === value);
      setSound(selectedSound || metronomeSounds[0]);

      // Save to local storage
      localStorage.setItem("metronomeSound", JSON.stringify(selectedSound));
    },
    [setSound]
  );

  return (
    <Selector
      options={options}
      onClick={_onSelect}
      label="Select..."
      selected={sound.name}
    />
  );
};
