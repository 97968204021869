export const fixed = (input: number, places = 2): number => {
  return Math.round(input * Math.pow(10, places)) / Math.pow(10, places);
};

export const sign = (input: number): string => {
  return input > 0 ? `+${input}` : input.toString();
};

export const clampToDecimalPlaces = (input: number, places = 2): number => {
  return Number(input.toFixed(places));
};

export async function loadAudioBuffer(
  context: AudioContext,
  url: string
): Promise<AudioBuffer> {
  const response = await fetch(url);
  const arrayBuffer = await response.arrayBuffer();
  return context.decodeAudioData(arrayBuffer);
}
