import { IMetronome, IMetronomeSound } from "src/types.d";
import { loadAudioBuffer } from "src/functions";
import { metronomeSounds, SIXTEENTH_IN_SAMPLES } from "src/constants";

export class MetronomeEngine implements IMetronome {
  private _tempo: number = 120;
  private _beatsPerBar: number = 4;
  private _division: number = 4;
  private _beatCount: number = 0;
  private _context: AudioContext;
  private _buffer: AudioBuffer | null = null;
  private _interval: NodeJS.Timer | null = null;
  private _isRunning: boolean = false;
  private _loaded: boolean = false;
  private _sound: IMetronomeSound = { ...metronomeSounds[3] };
  private _currentSource: AudioBufferSourceNode | null = null;

  constructor(onLoad?: () => void) {
    this._context = new AudioContext();

    // Default sound for now
    this.loadSound("/audio/metronomes.wav").then(() => {
      this._loaded = true;
      if (onLoad) {
        onLoad();
      }
    });
  }

  public async loadSound(url: string): Promise<void> {
    this._buffer = await loadAudioBuffer(this._context, url);
    console.log("MetronomeEngine sound loaded");
  }

  public start(immediate: boolean = true): void {
    if (!this._buffer) {
      console.error("MetronomeEngine: Audio buffer not loaded");
      return;
    }

    this._beatCount = 0;

    // account for division - if it is 4, this is baseline
    // If it is 8, interval is halved
    // If it is 16, interval is quartered
    // If it is 2, interval is doubled

    const f = this._division / 4; // 4 is the baseline

    const interval = (60 / this._tempo) * (1000 / f); // interval ms

    if (immediate) {
      this._playSound(this._sound.sampleStart); // Play the first beat immediately
      this._beatCount++;
    }

    this._interval = setInterval(() => {
      const { sampleStart } = this._sound;
      const isDownBeat = this._beatCount % this._beatsPerBar === 0;

      // We are running at 48kHz, so 3000 samples is equivalent to 1/16th note
      const startSample = isDownBeat ? sampleStart : sampleStart + SIXTEENTH_IN_SAMPLES;
      this._playSound(startSample); // Play the sound at the start of the buffer
      this._beatCount++; // This can just go on forever
    }, interval);

    this._isRunning = true;
  }

  public stop(): void {
    console.log("MetronomeEngine stop");
    if (this._interval) {
      clearInterval(this._interval);
      this._interval = null;
    }

    if (this._currentSource) {
      this._currentSource.stop();
      this._currentSource.disconnect();
      this._currentSource = null;
    }

    this._isRunning = false;
  }

  private _playSound(
    startSample: number,
    sampleCount: number = SIXTEENTH_IN_SAMPLES
  ): void {
    if (!this._buffer) {
      return;
    }

    // Create a new source & connect it to output
    const source = this._context.createBufferSource();
    source.buffer = this._buffer;
    source.connect(this._context.destination);

    // Start the source at the specified time - either start (downbeat) or halfway (division)
    const startTime = startSample / this._context.sampleRate;
    const duration = sampleCount / this._context.sampleRate;
    source.start(0, startTime, duration);

    this._currentSource = source;
    this._isRunning = true;
  }

  public setTempo(tempo: number): void {
    this._tempo = tempo;

    if (this._isRunning) {
      if (this._interval) {
        clearInterval(this._interval);
      }
      this.start(false);
    }
  }

  public setSignature(beats: number, division: number): void {
    console.log("MetronomeEngine setSignature", beats, division);
    this._beatsPerBar = beats;
    this._division = division;

    if (this._isRunning) {
      if (this._interval) {
        clearInterval(this._interval);
      }
      this.start(false);
    }
  }

  public setSound(sound: IMetronomeSound): void {
    console.log("MetronomeEngine setSound", sound);
    this._sound = sound;
  }

  public get tempo(): number {
    return this._tempo;
  }

  public get isRunning(): boolean {
    return this._isRunning;
  }

  public get loaded(): boolean {
    return this._loaded;
  }
}
