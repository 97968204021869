import { Check } from "@phosphor-icons/react";
import classNames from "classnames";
import { MouseEvent, useCallback, useContext, useEffect, useState } from "react";
import { MetronomeContext } from "src/context/MetronomeContext";

interface TimeSignatureSelectorProps {
  onFocus?: () => void;
  onBlur?: (event?: MouseEvent) => void;
}

export const TimeSignatureSelector: React.FC<TimeSignatureSelectorProps> = ({
  onFocus,
  onBlur,
}) => {
  const { timeSignature, setTimeSignature } = useContext(MetronomeContext);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [newSignature, setNewSignature] = useState<string>(timeSignature);

  useEffect(() => {
    setNewSignature(timeSignature);
  }, [timeSignature]);

  const _updateSignature = useCallback(
    (value: string) => {
      // const parts = newSignature.split(/[\/\|\-]/);
      // if (parts[1]) {
      //   const intendedValue = parseInt(`${parts[1]}${value.charAt(value.length - 1)}`);
      //   console.log(intendedValue);
      //   if (intendedValue > 16 || (intendedValue > 1 && intendedValue % 2 !== 0)) {
      //     return;
      //   }
      // }

      setNewSignature(value);
    },
    [setNewSignature]
  );

  useEffect(() => {
    if (isFocused && onFocus) {
      onFocus();
    } else if (!isFocused && onBlur) {
      onBlur();
      setNewSignature(timeSignature);
    }
  }, [isFocused, onFocus, onBlur, timeSignature]);

  const _onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.key === "Escape") {
      event.currentTarget.blur();
    }
  }, []);

  const className = classNames(
    "flex md:text-xl dark:bg-white dark:text-black py-1 px-2 lg:px-4 font-mono focus:outline-none focus:ring-4 focus:ring-yellow-400 transition-all duration-200 flex-none",
    {
      "rounded-l-md lg:max-w-[100px]": isFocused,
      "rounded-md": !isFocused,
      "max-w-[80px] lg:max-w-[100px]": timeSignature.length > 4,
      "max-w-[60px] lg:max-w-[80px]": timeSignature.length <= 4,
    }
  );

  const checkAndApplySignature = useCallback(() => {
    const parts = newSignature.split(/[/|-]/);
    console.log(parts);

    if (parts.length === 2 && ["2", "4", "8", "16"].includes(parts[1])) {
      setTimeSignature(newSignature);
    }
  }, [newSignature, setTimeSignature]);

  return (
    <div className="flex">
      <input
        className={className}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        value={newSignature}
        onChange={(event) => _updateSignature(event.target.value)}
        onKeyDown={_onKeyDown}
      />
      {isFocused && (
        <button
          onMouseDown={checkAndApplySignature}
          className="rounded-r-md bg-yellow-400 text-black px-2 lg:px-4 ring-4 ring-yellow-400 dark:bg-yellow-400 dark:text-black transition-all duration-200"
        >
          <Check weight="bold" />
        </button>
      )}
    </div>
  );
};
