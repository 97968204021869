export interface IMode {
  mode: string;
}

export enum Map {
  STRAIGHT = 1,
  DOTTED = 1.5,
  TRIPLET = 4 / 3,
}

export enum Mode {
  BPM_MS = 0,
  MS_BPM = 1,
  MS_HZ = 2,
  HZ_MS = 3,
  NOTES = 4,
  TEMPO_PITCH = 5,
  BPM_HZ = 6,
  METRONOME = 7,
}

export enum PitchTempoMode {
  FASTER = 0,
  SLOWER = 1,
  TARGET = 2,
}

export interface ITargetTempoOptions {
  higher?: {
    tempo: number;
    semitoneShift: number;
  };
  lower?: {
    tempo: number;
    semitoneShift: number;
  };
}

export interface IEnharmonicOptions {
  sharp: string[];
  flat: string[];
}

export interface IMetronome {
  start: (_: boolean) => void;
  stop: () => void;
  tempo: number;
  setTempo: (tempo: number) => void;
  setSound: (sound: IMetronomeSound) => void;
  setSignature: (beats: number, division: number) => void;
  isRunning: boolean;
}

export interface IMetronomeSound {
  name: string;
  sampleStart: number;
}

export interface ISelectorOption {
  label: string;
  path?: string;
}

export interface ISession {
  mode?: Mode;
  tempo?: number;
  ms?: number;
  hz?: number;
  octave?: number;
  enharmonicShift?: "sharp" | "flat";
}
