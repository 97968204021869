import { Screen } from "./Screen";
import { MouseEvent, useCallback, useContext, useState } from "react";
import { TempoInput } from "../components";
import { GlobalContext } from "src/context/global";
import { MetronomeUI } from "src/components/MetronomeUI";
import { SoundSelector } from "src/components/SoundSelector";
import { MetronomeProvider } from "src/context/MetronomeContext";
import { TimeSignatureSelector } from "src/components/TimeSignatureSelector";
import { TimeSignatureOptions } from "src/components/TimeSignatureOptions";

export const Metronome = () => {
  const { tempo } = useContext(GlobalContext);
  const [showTimeSignatureOptions, setShowTimeSignatureOptions] =
    useState<boolean>(false);

  const _onBlur = useCallback((event?: MouseEvent) => {
    setShowTimeSignatureOptions(false);
  }, []);
  return (
    <MetronomeProvider>
      <Screen>
        <div className="my-8 flex justify-between items-center gap-4">
          <div className="flex items-center gap-4">
            <TempoInput autoFocus />
            <TimeSignatureSelector
              onFocus={() => setShowTimeSignatureOptions(true)}
              onBlur={_onBlur}
            />
          </div>
          <SoundSelector />
        </div>
        <div className="relative">
          {showTimeSignatureOptions && <TimeSignatureOptions />}
        </div>
        {tempo && <MetronomeUI />}
      </Screen>
    </MetronomeProvider>
  );
};
