import classNames from "classnames";
import { NumericInput } from "./NumericInput";
import { useCallback, useContext } from "react";
import { GlobalContext } from "src/context/global";

interface TempoInputProps {
  setRoundValues?: (roundValues: boolean) => void;
  placeholder?: string;
  autoFocus?: boolean;
  roundValues?: boolean;
  manualTempo?: number;
  setManualTempo?: (manualTempo: number) => void;
}

export const TempoInput: React.FC<TempoInputProps> = ({
  autoFocus,
  placeholder,
  roundValues,
  setRoundValues,
  manualTempo,
  setManualTempo,
}) => {
  const { tempo, setTempo } = useContext(GlobalContext);

  const btnClassNm = classNames("btn", {
    active: roundValues,
    hidden: tempo ? isNaN(tempo) : true,
  });

  const _updateTempo = useCallback(
    (value: string) => {
      if (setManualTempo) {
        setManualTempo(Number(value));
      } else {
        setTempo(Number(value));
      }
    },
    [setTempo, setManualTempo]
  );

  return (
    <div>
      <NumericInput
        value={setManualTempo !== undefined ? manualTempo : tempo}
        setValue={_updateTempo}
        placeholder={placeholder || "BPM"}
        label="BPM"
        autoFocus={autoFocus}
      />

      {roundValues && setRoundValues && (
        <button
          className={btnClassNm}
          onClick={() => {
            setRoundValues(!roundValues);
          }}
        >
          Nearest ms
        </button>
      )}
    </div>
  );
};
