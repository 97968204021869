import { createContext, useState, ReactNode, useEffect } from "react";
import { ISession, Mode } from "src/types.d";

interface GlobalContextProps {
  mode?: Mode;
  setMode: (mode: Mode) => void;
  tempo?: number;
  setTempo: (tempo: number) => void;
  ms?: number;
  setMs: (ms: number) => void;
  hz?: number;
  setHz: (hz: number) => void;
  octave: number;
  setOctave: (octave: number) => void;
  enharmonicShift: "sharp" | "flat";
  setEnharmonicShift: (enharmonicShift: "sharp" | "flat") => void;
}

export const GlobalContext = createContext<GlobalContextProps>({
  mode: undefined,
  setMode: () => {},
  tempo: undefined,
  setTempo: () => {},
  ms: undefined,
  setMs: () => {},
  hz: undefined,
  setHz: () => {},
  octave: 4,
  setOctave: () => {},
  enharmonicShift: "sharp",
  setEnharmonicShift: () => {},
});

export const GlobalProvider = ({ children }: { children: ReactNode }) => {
  const [mode, setMode] = useState<Mode>();
  const [tempo, setTempo] = useState<number>();
  const [ms, setMs] = useState<number>();
  const [hz, setHz] = useState<number>();
  const [octave, setOctave] = useState<number>(4);
  const [enharmonicShift, setEnharmonicShift] = useState<"sharp" | "flat">("sharp");

  // When changes are made, store to local storage
  useEffect(() => {
    const session: ISession = {
      mode,
      tempo,
      ms,
      hz,
      octave,
      enharmonicShift,
    };

    localStorage.setItem("session", JSON.stringify(session));
  }, [mode, tempo, ms, hz, octave, enharmonicShift]);

  return (
    <GlobalContext.Provider
      value={{
        mode,
        setMode,
        tempo,
        setTempo,
        ms,
        setMs,
        hz,
        setHz,
        octave,
        setOctave,
        enharmonicShift,
        setEnharmonicShift,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
