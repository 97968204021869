export const BuyMeACoffee: React.FC = () => {
  return (
    <a
      href="https://www.buymeacoffee.com/steviej"
      target="_blank"
      rel="noopener noreferrer"
      className="block rounded-lg overflow-hidden w-40"
    >
      <img
        src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
        alt="Buy Me A Coffee"
      />
    </a>
  );
};
