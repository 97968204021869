import React from "react";
import { BpmToHz } from "./views/BpmToHz";
import { Metronome } from "./views/Metronome";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home, BpmToMs, MsToBpm, Notes, PitchAndTempo, MsToHz, HzToMs } from "./views";

const AppRouter: React.FC = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="bpm-to-ms" element={<BpmToMs />} />
      <Route path="ms-to-bpm" element={<MsToBpm />} />
      <Route path="notes" element={<Notes />} />
      <Route path="metronome" element={<Metronome />} />
      <Route path="tempo-pitch" element={<PitchAndTempo />} />
      <Route path="bpm-to-hz" element={<BpmToHz />} />
      <Route path="ms-to-hz" element={<MsToHz />} />
      <Route path="hz-to-ms" element={<HzToMs />} />
    </Routes>
  </Router>
);

export default AppRouter;
