import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { GlobalContext } from "./global";
import { metronomeSounds } from "src/constants";
import { IMetronome, IMetronomeSound } from "src/types.d";
import { MetronomeEngine } from "src/utilities/MetronomeEngine";

interface MetronomeContextProps {
  engine: IMetronome | undefined;
  timeSignature: string;
  setTimeSignature: (timeSignature: string) => void;
  beatsPerBar: number;
  setBeatsPerBar: (beatsPerMeasure: number) => void;
  division: number;
  setDivision: (division: number) => void;
  sound: IMetronomeSound;
  setSound: (sound: IMetronomeSound) => void;
  running: boolean;
  setRunning: (running: boolean) => void;
}

export const MetronomeContext = createContext<MetronomeContextProps>({
  engine: undefined,
  timeSignature: "4/4",
  setTimeSignature: () => {},
  beatsPerBar: 4,
  setBeatsPerBar: () => {},
  division: 4,
  setDivision: () => {},
  sound: { ...metronomeSounds[0] },
  setSound: (_: IMetronomeSound) => {},
  running: false,
  setRunning: () => {},
});

export const MetronomeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { tempo } = useContext(GlobalContext);

  const engine = useMemo(() => new MetronomeEngine(), []);
  const [timeSignature, setTimeSignature] = useState<string>("4/4");
  const [beatsPerBar, setBeatsPerBar] = useState<number>(4);
  const [division, setDivision] = useState<number>(4);
  const [sound, setSound] = useState<IMetronomeSound>({ ...metronomeSounds[0] });
  const [running, setRunning] = useState<boolean>(false);

  useEffect(() => {
    if (engine) {
      if (running) {
        if (!engine.isRunning) {
          engine.start();
        }
      } else {
        if (engine.isRunning) {
          engine.stop();
        }
      }
    }
  }, [running, engine]);

  useEffect(() => {
    if (engine && tempo) {
      engine.setTempo(tempo);
    }
  }, [tempo, engine]);

  // Signature
  useEffect(() => {
    const [beats, division] = timeSignature.split("/").map((v) => parseInt(v));
    if (engine) {
      engine.setSignature(beats, division);
    }
  }, [timeSignature]);

  useEffect(() => {
    if (engine && sound) {
      engine.setSound(sound);
    }
  }, [sound, engine]);

  // Load local storage on load
  useEffect(() => {
    const sound = localStorage.getItem("metronomeSound");

    if (sound) {
      console.log("Loading sound from local storage");
      setSound(JSON.parse(sound));
    }
  }, []);

  return (
    <MetronomeContext.Provider
      value={{
        engine,
        timeSignature,
        setTimeSignature,
        beatsPerBar,
        setBeatsPerBar,
        division,
        setDivision,
        sound,
        setSound,
        running,
        setRunning,
      }}
    >
      {children}
    </MetronomeContext.Provider>
  );
};
