import { BuyMeACoffee } from "./BuyMeACoffee";

export const Footer: React.FC = () => {
  return (
    <footer>
      <div className="container mx-auto px-4 lg:px-8 py-4 flex flex-col items-center gap-4">
        <BuyMeACoffee />
        <p className="text-center dark:text-zinc-400">
          © {new Date().getFullYear()}{" "}
          <a
            href="https://bsky.app/profile/stevejones.music"
            className="underline dark:hover:text-white"
            target="_blank"
            rel="noopener noreferrer"
          >
            Stevie J Jones
          </a>
          . All rights reserved.
        </p>
      </div>
    </footer>
  );
};
