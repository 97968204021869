import { divisions } from "src/constants";
import { Table, TableCell, TableHeader } from "./Table";

interface ColumnBpmToHzProps {
  tempo: number;
  factor: number;
}

export const ColumnBpmToHz: React.FC<ColumnBpmToHzProps> = ({ tempo, factor }) => {
  // Factor gives triplets / dotted notes
  return (
    <Table>
      <thead>
        <tr>
          <TableHeader>Note</TableHeader>
          <TableHeader>Hz</TableHeader>
        </tr>
      </thead>
      <tbody>
        {divisions.map((division, index) => {
          // round to 2 decimal places numerically

          // At 60 bpm, a 1/4 note is 1 second, so 1 Hz
          // At 60 bpm, an 1/8 notes is 0.5s, so 2Hz

          // At 120bpm, a 1/4 notes is 0.5s, so 2Hz
          const numBeats = 4 / division;
          // const value = (60 / tempo / numbBeats) / factor;
          // round to 2 decimal places numerically
          const value = Math.round((tempo / 60 / numBeats) / factor * 1000) / 1000;

          return (
            <tr key={index}>
              <TableCell mono>1/{division}</TableCell>
              <TableCell mono>{value}</TableCell>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
