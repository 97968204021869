import classNames from "classnames";
import React, { MouseEvent, useContext } from "react";
import { timeSignatureOptions } from "src/constants";
import { MetronomeContext } from "src/context/MetronomeContext";

export const TimeSignatureOptions: React.FC = () => {
  const { timeSignature, setTimeSignature } = useContext(MetronomeContext);

  return (
    <div>
      <div className="absolute top-0 left-0 w-full flex flex-col gap-2 items-center dark:bg-zinc-800 backdrop-blur-md p-4 rounded-md">
        <p>Choose from common signatures or type it above</p>
        {timeSignatureOptions.map((group, index) => {
          return (
            <div key={`g${index}`} className="flex gap-2 w-full">
              {group.map((option, index) => {
                const btnClassName = classNames(
                  "px-2 py-1 dark:hover:ring-2 dark:hover:ring-yellow-400 dark:hover:bg-zinc-800 transition-all duration-200 ease-in-out flex-1",
                  {
                    "bg-zinc-200 dark:bg-zinc-900 dark:ring-2 dark:ring-zinc-300":
                      option === timeSignature,
                    "dark:bg-zinc-600 hover:bg-zinc-300": option !== timeSignature,
                  }
                );

                return (
                  <button
                    key={`o${index}`}
                    onMouseDown={(event: MouseEvent) => {
                      event.preventDefault();
                      console.log("Setting time signature to", option);
                      setTimeSignature(option);
                    }}
                    className={btnClassName}
                  >
                    {option}
                  </button>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
