import { useContext } from "react";
import { Pause, Play } from "@phosphor-icons/react";
import { MetronomeContext } from "src/context/MetronomeContext";

export const MetronomeUI: React.FC = () => {
  const { running, setRunning } = useContext(MetronomeContext);

  return (
    <div className="flex justify-center flex-1 flex-col items-center gap-12">
      <button
        onClick={() => setRunning(!running)}
        className="border-2 rounded-full border-white p-4 w-32 h-32 flex justify-center items-center hover:bg-white hover:bg-opacity-10 transition duration-300"
      >
        {running ? <Pause weight="fill" size={64} /> : <Play weight="fill" size={64} />}
      </button>
    </div>
  );
};
